<template>
    <div class="row mt-0 justify-content-center">
        <HXContentCard title="Ruta Recorrida por el Vehiculo" :toBack="{ name: 'ListaEntregas' }" />
        <div class="col-11 row  bg-dark p-2 rounded border">
            <div class="col-10 pl-0 pr-2 ">
                <l-map :options="mapOptions" style="height: 70vh !important ; z-index: 3 !important" :zoom="zoom"
                    :center="center" class="rounded " @ready="onMapReady">
                    <l-tile-layer :url="url" :attribution="attribution" />

                    <l-icon-default />
                    <l-control-scale position="bottomleft" :imperial="false" :metric="true"></l-control-scale>

                    <!-- Polilínea -->
                    <div v-for="(coordenadas, puntoEntregaId, index) in polylinePath" :key="puntoEntregaId">
                        <l-polyline :lat-lngs="coordenadas" :options="polylineOptions"
                            :color="polyLineColors[index]"></l-polyline>

                        <!-- Marcador inicial -->
                        <l-marker :icon="customIconIni" :lat-lng="coordenadas[0]" ref="markerRefIni"></l-marker>

                        <!-- Marcador final -->
                        <l-marker :icon="customIconFin" :lat-lng="coordenadas[coordenadas.length - 1]" ref="map"
                            @click="abrirInfoRuta(puntoEntregaId)">
                            <l-popup class="custom-popup">
                                <div class="popup-principal">
                                    <div class="popup-header">
                                        <h4 class="route-title">
                                            <span class="material-icons">
                                                <i class="fa-solid fa-route"></i>
                                            </span>
                                            {{
                                                rutaInformacionCompleta.rutas
                                                    ? rutaInformacionCompleta.rutas[0].nombreRuta
                                                    : "Sin ruta"
                                            }}
                                        </h4>
                                    </div>
                                    <div class="placa">
                                        <div class="placa-informacion">
                                            <span class="col">COL</span>
                                            <span class="numeroPlaca">{{
                                                rutaInformacionCompleta.rutas
                                                    ? rutaInformacionCompleta.rutas[0].placa
                                                    : "Sin ruta"
                                            }}</span>
                                        </div>
                                    </div>
                                    <div class="popup-content">
                                        <div class="info-item">
                                            <span class="material-icons">
                                                <i class="fa-solid fa-truck-fast"></i>
                                            </span>
                                            <div class="info-text">
                                                <label>Punto de entrega</label>
                                                <p>
                                                    {{
                                                        rutaInformacionCompleta.puntoEntrega
                                                            ? rutaInformacionCompleta.puntoEntrega.plantillaRutas
                                                                .lugarEntrega
                                                            : "Sin punto de entrega"
                                                    }}
                                                </p>
                                            </div>
                                        </div>

                                        <div class="info-item">
                                            <span class="material-icons">
                                                <i class="fa-solid fa-calendar-days"></i>
                                            </span>
                                            <div class="info-text">
                                                <label>Inicio programado</label>
                                                <p>
                                                    {{
                                                        rutaInformacionCompleta.rutas
                                                            ? rutaInformacionCompleta.rutas[0].fechaHoraInicioRuta
                                                            : "Sin ruta"
                                                    }}
                                                </p>
                                            </div>
                                        </div>

                                        <div class="info-item">
                                            <span class="material-icons">
                                                <i class="fa-solid fa-file-circle-question"></i>
                                            </span>
                                            <div class="info-text">
                                                <label>Descripción</label>
                                                <p>
                                                    {{
                                                        rutaInformacionCompleta.rutas
                                                            ? rutaInformacionCompleta.rutas[0].descripcionRuta
                                                            : "Sin ruta"
                                                    }}
                                                </p>
                                            </div>
                                        </div>

                                        <div v-if="rutaInformacionCompleta.puntoEntrega" class="status-container">
                                            <div :class='rutaInformacionCompleta.puntoEntrega.estadoPuntoEntrega == "En camino" ? "status-badge"
                                                : "status-badge-warning"'>
                                                {{
                                                    rutaInformacionCompleta.puntoEntrega
                                                        ? rutaInformacionCompleta.puntoEntrega
                                                            .estadoPuntoEntrega
                                                        : "Sin estado"
                                                }}
                                            </div>
                                            <div class="coordinates-badge">
                                                <span class="material-icons">
                                                    <i class="fa-solid fa-location-dot"></i>
                                                </span>
                                                {{ coordenadas.length }} coordenadas
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </l-popup>
                        </l-marker>
                    </div>
                    <!-- Botón de modo nocturno -->
                    <l-control position="topright">
                        <vs-button @click.prevent="toggleStyles" :color="modoNocturno ? 'warn' : 'dark'">
                            <i :class="modoNocturno ? 'fa-regular fa-sun' : 'fa-solid fa-moon'" style="width: 15px"></i>
                        </vs-button>
                    </l-control>
                </l-map>
            </div>
        </div>
    </div>
</template>

<script>
import {
    LMap,
    LTileLayer,
    LMarker,
    LPolyline,
    LControlScale,
    LControl,
    LIconDefault,
    LPopup,
} from "vue2-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

export default {
    components: {
        LMap,
        LTileLayer,
        LMarker,
        LPolyline,
        LControlScale,
        LControl,
        LIconDefault,
        LPopup,
    },
    data() {
        return {
            map: null,
            desde: this.$moment
                .tz(new Date(), "America/Costa_Rica")
                .format("YYYY-MM-DD HH:mm"),
            hasta: this.$moment
                .tz(new Date(), "America/Costa_Rica")
                .format("YYYY-MM-DD HH:mm"),
            velocidadProm: 0,
            servicioSeleccionado: null,
            duracion: 0,
            url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
            attribution:
                '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            zoom: 14,
            center: [4.7279077, -74.062912],
            customIconIni: L.icon({
                iconUrl: require("@/assets/images/homeIcon.png"),
                iconSize: [40, 45],
                iconAnchor: [16, 16],
                popupAnchor: [10, -32],
            }),
            customIconFin: L.icon({
                iconUrl: require("@/assets/images/vehiculoHelexium.png"),
                iconSize: [40, 45],
                iconAnchor: [16, 16],
                popupAnchor: [10, -32],
            }),
            customIcon: L.icon({
                iconUrl: require("@/assets/images/vehiculoHelexium.png"),
                iconSize: [40, 45],
                iconAnchor: [16, 16],
                popupAnchor: [10, -32],
            }),

            persona: {},
            empresaId: {},
            contadorProgreso: 0,
            distanciaTotal: 0,
            markers: [],
            mapOptions: {
                autoClosePopup: false, // Evitar que el mapa cierre automáticamente los popups
            },

            polylinePath: [],
            intermediateMarkers: [],
            polylineOptions: {
                weight: 5,
            },
            polyLineColors: [
                "#085ec5", // Azul
                "#ee002b", // Rojo
                "#00FF00", // Verde brillante
                "#0000FF", // Azul
                "#FFFF00", // Amarillo
                "#FF00FF", // Magenta
                "#00FFFF", // Cian
                "#800000", // Marrón
                "#808000", // Verde oliva
                "#008080", // Verde azulado oscuro
                "#800080", // Púrpura
                "#FFA500", // Naranja
                "#A52A2A", // Marrón rojizo
                "#7CFC00", // Verde pasto
                "#FFD700", // Dorado
                "#4B0082", // Índigo
                "#DC143C", // Carmesí
                "#FF1493", // Rosa fuerte
                "#20B2AA", // Verde azulado
                "#2E8B57", // Verde bosque
                "#1E90FF", // Azul Dodger
                "#FF6347", // Tomate
                "#ADFF2F", // Verde amarillento
                "#8B0000", // Rojo oscuro
                "#696969", // Gris oscuro
                "#000000"  // Negro
            ],

            modoNocturno: false,

            //RUTAS, PUNTOS Y COORDENADAS INICIADAS
            rutasIniciadas: [],
            puntosEntregaIniciados: [],
            coordenadasIniciadas: [],

            rutaInformacionCompleta: [],
            rutaInformacion: []
        };
    },
    async mounted() {
        this.persona = JSON.parse(localStorage.getItem("setPersonaAct"));
        this.empresaId = this.persona.empresa.id;
        await this.getRutasIniciadas();
        await this.getPuntosEntregaIniciados();
        await this.getCoordenadasIniciadas()
        this.actualizarCoordenadas = setInterval(async () => {
            await this.getCoordenadasIniciadas();
            console.log("Actualizando información")
        }, 5000);
    },
    beforeUnmount() {
        clearInterval(this.actualizarCoordenadas);
    },
    methods: {
        async abrirInfoRuta(puntoRutaId) {
            console.log("VIENDO LA INFO DE LA RUTA", puntoRutaId);

            await this.filtrarDatosPorId(puntoRutaId);
        },

        async getRutasIniciadas() {
            try {
                let res = await this.$store.dispatch("hl_get", {
                    path: "EntregasMap/RutasIniciadas",
                });
                console.log("LAS RUTAS INICIADAS SON:", res);
                if (res) {
                    this.rutasIniciadas = res;
                } else {
                    this.rutasIniciadas = [];
                    console.log("No se encontraron puntos de entrega para esta empresa");
                }
            } catch (error) {
                this.rutasIniciadas = [];
                console.log("Error al obtener los datos:", error);
            }
        },
        async getPuntosEntregaIniciados() {
            try {
                const ids = await this.rutasIniciadas.map((ruta) => ruta.id);
                const result = { ids };
                console.log("Estos son los ids necesarios", ids);

                let res = await this.$store.dispatch("hl_post", {
                    path: "EntregasMap/PuntosEntregaIniciados",
                    data: result,
                });
                console.log("LOS PUNTOS DE ENTREGA INICIADOS SON:", res);
                if (res) {
                    this.puntosEntregaIniciados = res;
                } else {
                    this.puntosEntregaIniciados = [];
                    console.log("No se encontraron puntos de entrega iniciados");
                }
            } catch (error) {
                this.puntosEntregaIniciados = [];
                console.log("Error al obtener los datos:", error);
            }
        },

        async getCoordenadasIniciadas() {
            try {
                const ids = await this.puntosEntregaIniciados.map((ruta) => ruta.id);
                const result = { ids };
                console.log("Estos son los ids necesarios (Coordenadas)", result);

                let res = await this.$store.dispatch("hl_post", {
                    path: "EntregasMap/CoordenadasIniciadas",
                    data: result,
                });
                console.log("LAS COORDENADAS INICIADAS SON:", res);
                if (res) {
                    this.coordenadasIniciadas = res;
                    let puntos = this.coordenadasIniciadas.reduce((acc, grupo) => {
                        acc[grupo.puntoEntregaId] = grupo.coordenadas.map((coordenada) => [
                            coordenada.latUbicacionActual,
                            coordenada.longUbicacionActual,
                        ]);
                        return acc;
                    }, {});
                    this.polylinePath = puntos;
                    await this.agruparDataRuta(puntos);
                    console.log("COORDENADAS DE PARA EL POLY", puntos);


                } else {
                    this.coordenadasIniciadas = [];
                    console.log("No se encontraron puntos de entrega iniciados");
                }
            } catch (error) {
                this.coordenadasIniciadas = [];
                console.log("Error al obtener los datos:", error);
            }
        },

        toggleStyles() {
            this.modoNocturno = !this.modoNocturno;
            this.applyStyles();
        },
        applyStyles() {
            const elements = document.querySelectorAll(
                ".leaflet-layer, .leaflet-control-zoom-in, .leaflet-control-zoom-out, .leaflet-control-attribution"
            );

            elements.forEach((element) => {
                if (this.modoNocturno) {
                    element.style.filter =
                        "invert(100%) hue-rotate(250deg) brightness(80%) contrast(90%)";
                } else {
                    element.style.filter = ""; // Limpia los estilos para regresar a los predeterminados
                }
            });
        },
        updateRoute(newCoordinates) {
            this.polylinePath = newCoordinates;
        },

        async filtrarDatosPorId(puntoEntregaId) {
            // Filtra el arreglo principal para obtener el punto de entrega por ID
            const puntoEntrega = this.puntosEntregaIniciados.find(
                (grupo) => grupo.id === puntoEntregaId
            );
            console.log("--------------------------------", puntoEntrega);
            // Si no se encuentra el punto de entrega, retorna un arreglo vacío
            if (!puntoEntrega) return [];

            // Obtener el ID de la ruta principal desde el punto de entrega encontrado
            const rutaPrincipalId = puntoEntrega.puntosEntregaGeneralesId;

            const rutasFiltradas = this.rutasIniciadas.filter(
                (ruta) => ruta.id === rutaPrincipalId
            );
            const resultado = {
                puntoEntrega: puntoEntrega,
                rutas: rutasFiltradas,
            };
            this.rutaInformacionCompleta = resultado;
            console.log(resultado);
            return resultado;
        },

        async agruparDataRuta() {
            const puntosEntrega = this.puntosEntregaIniciados;
            if (!puntosEntrega || puntosEntrega.length === 0) return [];

            const rutasPrincipalesIds = puntosEntrega.map(
                (grupo) => grupo.puntosEntregaGeneralesId
            );

            const puntosEntregaId = puntosEntrega.map((grupo) => grupo.id);

            const rutasFiltradas = this.rutasIniciadas.filter(
                (ruta) => rutasPrincipalesIds.includes(ruta.id)
            );

            const ultimasCoordenadas = await this.obtenerUltimasCoordenadas(puntosEntregaId);
            const rutasAgrupadas = puntosEntrega.map((puntoEntrega) => {
                const rutasParaPunto = rutasFiltradas.filter(
                    (ruta) => ruta.id === puntoEntrega.puntosEntregaGeneralesId
                );

                const ultimaCoordenada = ultimasCoordenadas[puntoEntrega.id] || null;

                return {
                    ...puntoEntrega,
                    rutas: rutasParaPunto,
                    ultimaCoordenada: ultimaCoordenada,
                };
            });

            this.rutaInformacion = rutasAgrupadas;

            console.log("Estas son todas las rutas agrupadas", rutasAgrupadas);
            return rutasAgrupadas;
        },


        async obtenerUltimasCoordenadas(ids) {
            const resultado = {};

            await ids.forEach((id) => {
                const coordenadasPunto = this.polylinePath[id];

                if (coordenadasPunto && coordenadasPunto.length > 0) {
                    resultado[id] = coordenadasPunto[coordenadasPunto.length - 1];
                } else {
                    resultado[id] = null;
                }
            });
            console.log("Estas son las últimas coordenadas", resultado);
            return resultado;
        },

        onMapReady(mapInstance) {
            this.map = mapInstance;
        },
        redirectMap(redireccionCoordenadas) {
            console.log("Redirect", redireccionCoordenadas)
            if (this.map) {
                this.map.flyTo([redireccionCoordenadas[0], redireccionCoordenadas[1]], 16, {
                    duration: 2, // Duración del vuelo
                });
            }

        }


    },
    watch: {
        modoNocturno() {
            this.applyStyles();
        },
    },
};
</script>
<style>
.leaflet-control-zoom-in span {
    color: black !important;
}

.leaflet-control-zoom-out span {
    color: black !important;
}

.leaflet-popup-content {
    margin: 0 !important;
}

.leaflet-popup-content-wrapper {
    padding: 0 !important;
}
</style>
<style scoped>
.activated:focus {
    border: 2px solid white;
}


.custom-popup {

    .popup-principal {
        padding: 0 !important;
        margin: 0 !important;
        width: 302px !important;
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    }

    .popup-header {
        background-color: #f21343;
        color: white;
        padding: 16px;

        .route-title {
            margin: 0;
            display: flex;
            align-items: center;
            gap: 8px;
            font-size: 1.2rem;
            color: white;

            .material-icons {
                font-size: 24px;
            }
        }
    }

    .placa {
        background: linear-gradient(145deg, #f0f0f0, #ffffff);
        margin: 16px auto;
        width: 200px;
        border: 2px solid #000;
        border-radius: 4px;
        padding: 8px;

        .placa-informacion {
            text-align: center;

            .col {
                display: block;
                font-size: 0.8rem;
                color: #000000;
                margin-bottom: 4px;
            }

            .numeroPlaca {
                font-size: 1.5rem;
                font-weight: bold;
                letter-spacing: 2px;
                color: #000;
            }
        }
    }

    .popup-content {
        padding: 16px;

        .info-item {
            display: flex;
            gap: 12px;
            margin-bottom: 16px;
            align-items: flex-start;

            .material-icons {
                color: #666;
                font-size: 20px;
            }

            .info-text {
                flex: 1;

                label {
                    font-size: 0.8rem;
                    color: #666;
                    margin-bottom: 4px;
                    display: block;
                }

                p {
                    margin: 0;
                    color: #333;
                }
            }
        }
    }

    .status-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 16px;
        padding-top: 16px;
        border-top: 1px solid #eee;

        .status-badge {
            padding: 6px 12px;
            border-radius: 16px;
            font-size: 0.9rem;
            font-weight: 500;
            background-color: #E8F5E9;
            color: #2E7D32;
        }

        .status-badge-warning {
            padding: 6px 6px;
            border-radius: 16px;
            font-size: 0.8rem;
            font-weight: 200;
            background-color: #ffefbbde;
            color: #a38500;
        }

        .coordinates-badge {
            display: flex;
            align-items: center;
            gap: 4px;
            color: #666;
            font-size: 0.9rem;

            .material-icons {
                font-size: 18px;
            }
        }
    }
}
</style>
